/**
 * DocListPage template that...
 *
 */
import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ContentCard from '../components/content-card'
import Pager from '../components/pager'
import useContentData from '../hooks/use-content-data'
import { ContentNode } from '../types/content'

const DocListPage = ({ data, pageContext }) => {
  const docs = data.allMdx.edges
  //console.log('-----DocListPage: ', data, pageContext)
  return (
    <Layout>
      <main className="l-main">
        <div className="l-constrained text-highlight">
          <h2>Documents</h2>
          {docs.map((edge: ContentNode) => {
            const node = useContentData(edge)
            return <ContentCard key={node.id} {...node} />
          })}
          <Pager {...pageContext} />
        </div>
      </main>
    </Layout>
  )
}

export const docListQuery = graphql`
  query docListQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: {
        fields: { collection: { eq: "docs" } }
        frontmatter: { published: { eq: true } }
      }
      sort: { order: ASC, fields: [frontmatter___title] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            link
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default DocListPage
